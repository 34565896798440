import { graphql } from 'gatsby';
import ProductPage from 'gatsby-theme-dettol/src/templates/ProductPage/ProductPage';

export default ProductPage;

export const query = graphql`
   query (
    $link: String!
    $relatedProductLinks: [String] = ""
    $sizeScentFriendlyProductLinks: [String] = ""
    $lang: String
  ) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
      lang
      useBV
      buttonBuyNow
      orderNowFrom
      buttonCancel
      relatedProductsRegularTitle
      relatedProductsBoldTitle
      faqTitle
    }
    brandSettings {
      brandName
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
      pushSelectedEventGTM
      newsletterBanner {
        buttonText
        buttonURL {
          url
        }
        titleText
        regularTitle
      }
      discountBanner {
        buttonText
        buttonURL {
          url
          queryString
        }
        titleText
        regularTitle
      }
    }
    sizeScentFriendlyProducts: allUmbracoProduct(
      filter: { link: { in: $sizeScentFriendlyProductLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        link
        size
        scent
      }
    }
    product: umbracoProduct(link: { eq: $link }, lang: { eq: $lang }) {
      seoNoIndex
      seoMetaKeywords
      seoMetaDescription
      seoMetaTitle
      seoCanonicalUrl
      seoImage
      ogPageType
      seoExternalHreflangs {
        key
        value
      }
      name
      sku
      ean
      umbracoId
      link
      buyNow {
        properties {
          link {
            url
          }
          shopName
          extraClass
          name
        }
      }
      titleBrandbank
      scent
      productColor
      size
      descriptionLong
      descriptionShort
      images
      backgroundImage {
        fluid {
          srcSet
          base64
        }
        fallbackUrl
      }
      tagsLabelText
      heroImage
      tags {
        id
        title
        name
        visible
      }
      localHeroImage {
        childImageSharp {
          fluid(maxWidth: 430, quality:90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      didYouKnow {
        labelText
        descriptionText
        buttonText
        imageAlt
        ariaLabel
        didYouKnowBG {
          fluid {
            srcSet
            base64
          }
          fallbackUrl
        }
        buttonURL {
          url
          icon
        }
      }
      productBody {
        structure
        properties {
          description
          iconName
          title
          titleRegular
          titleBold
          listTitleText
          showMoreText
          actionIconName
          actionLabelText
          articleSectionDescription
          recommendations {
            properties {
              answer
              question
            }
          }
          features {
            properties {
              description
              descriptionRte
              imageAlias
            }
          }
          group {
            properties {
              name
              composition
              iconName
            }
          }
          image {
            fallbackUrl
            fluid {
              srcSet
              base64
            }
          }
          imageAlt
          productImage {
            fallbackUrl
            fluid {
              srcSet
              base64
            }
          }
          productImageAlt
          instructions {
            properties {
              description
              iconName
            }
          }
          questionAnswerItems {
            properties {
              answer
              question
            }
          }
          videoUrl
          buttonLink {
            url
            name
          }
          buttonText
          additionalText
        }
      }
      category
    }
    relatedProducts: allUmbracoProduct(
      filter: { link: { nin: [$link], in: $relatedProductLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        id
        link
        size
        localHeroImage {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        name
        recognisableFeature
        descriptionLong
        descriptionShort
        titleBrandbank
      }
    }
  }
`;